import _NetworkError from "./NetworkError.js";
var exports = {};
const NetworkError = _NetworkError;
/**
 * Wrapper around window.fetch that throws a NetworkError when appropriate
 */

function fetchWithNetworkError() {
  return fetch(...arguments).catch(err => {
    if (err.name === "AbortError") {
      throw err;
    } else {
      throw new NetworkError(err);
    }
  });
}

exports = fetchWithNetworkError;
export default exports;